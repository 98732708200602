import React from 'react';
import idx from 'idx';
import { graphql } from 'gatsby';
import ApplyForGrant from './ApplyForGrant';

const ApplyForGrantQuery = props => {
  const heroEyebrow = idx(props, _ => _.heroEyebrow);
  const heroTitle = idx(props, _ => _.heroTitle);
  const heroDesktopBackgroundImage = idx(
    props,
    _ => _.heroDesktopBackgroundImage
  );

  const heroMobileBackgroundImage = idx(
    props,
    _ => _.heroMobileBackgroundImage
  );

  const grantInstructions = idx(
    props,
    _ => _.grantInstructions.childMarkdownRemark.html
  );
  const grantInstructionsTitle = idx(props, _ => _.grantInstructionsTitle);
  const grantInstructionsSubtitle = idx(
    props,
    _ => _.grantInstructionsSubtitle
  );

  const grantApplyCtaCopy = idx(props, _ => _.grantApplyCtaCopy);
  const grantApplyCtaUrl = idx(props, _ => _.grantApplyCtaUrl);
  const grantInstructionsImage = idx(props, _ => _.grantInstructionsImage);

  const communityResourcesModule = idx(props, _ => _.communityResourcesModule);
  const resourcesModule = {
    eyebrow: idx(communityResourcesModule, _ => _.eyebrow),
    title: idx(communityResourcesModule, _ => _.title),
    subtitle: idx(communityResourcesModule, _ => _.subtitle),
    communityResourcesList: (
      idx(communityResourcesModule, _ => _.communityResourcesList) || []
    ).map(resource => {
      return {
        category: idx(resource, _ => _.category),
        title: idx(resource, _ => _.title),
        description: idx(resource, _ => _.description.childMarkdownRemark.html),
        url: idx(resource, _ => _.url),
      };
    }),
  };

  const grantTestimonialModule = idx(props, _ => _.grantTestimonialModule);

  return (
    <ApplyForGrant
      heroEyebrow={heroEyebrow}
      heroTitle={heroTitle}
      heroDesktopBackgroundImage={heroDesktopBackgroundImage}
      heroMobileBackgroundImage={heroMobileBackgroundImage}
      grantInstructions={grantInstructions}
      grantInstructionsTitle={grantInstructionsTitle}
      grantInstructionsSubtitle={grantInstructionsSubtitle}
      grantApplyCtaCopy={grantApplyCtaCopy}
      grantApplyCtaUrl={grantApplyCtaUrl}
      grantInstructionsImage={grantInstructionsImage}
      grantTestimonialModule={grantTestimonialModule}
      communityResourcesModule={resourcesModule}
    />
  );
};

export default ApplyForGrantQuery;

export const query = graphql`
  fragment ApplyForGrant on ContentfulApplyForGrantPage {
    heroEyebrow
    heroTitle
    heroDesktopBackgroundImage {
      description
      fluid(maxWidth: 1920, quality: 70) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    heroMobileBackgroundImage {
      description
      fluid(maxWidth: 1200, quality: 70) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }

    grantInstructionsTitle
    grantInstructionsSubtitle

    grantInstructions {
      childMarkdownRemark {
        html
      }
    }

    grantInstructionsImage {
      description
      fluid(maxWidth: 534, quality: 70) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }

    grantApplyCtaCopy
    grantApplyCtaUrl
    grantTestimonialModule {
      ...GrantTestimonial
    }

    communityResourcesModule {
      eyebrow
      title
      subtitle
      communityResourcesList {
        category
        title
        description {
          childMarkdownRemark {
            html
          }
        }
        url
      }
    }
  }
`;
