import React from 'react';
import { graphql } from 'gatsby';
import Meta from '@shared/Meta/Meta';
import DefaultLayout from '@layouts';
import ApplyForGrant from '@components/ApplyForGrant/ApplyForGrant.query';
import DonationModule from '@components/shared/DonationModule/DonationModule.query';
import SocialModule from '@components/shared/SocialModule/SocialModule';

const Grant = ({ data }) => {
  const applyForGrant = data.allContentfulApplyForGrantPage.edges.map(
    edge => edge.node
  )[0];

  const globalSiteData = data.allContentfulGlobalSiteData.edges.map(
    edge => edge.node
  )[0];

  const {
    facebookLink,
    twitterLink,
    instagramLink,
    socialModuleEyebrow,
    socialModuleTitle,
    socialModuleSubtitle,
    donationModuleEyebrow,
    donationModuleTitle,
    donationModuleSubtitle,
    mailingAddressLine1,
    mailingAddressLine2,
    mailingAddressCity,
    mailingAddressState,
    mailingAddressZipCode,
    cookieBannerCopy,
    cookieBannerCtaCopy,
    phoneNumber,
    contactEmails,
    youTubeLink,
  } = globalSiteData;

  const footerData = {
    mailingAddressLine1,
    mailingAddressLine2,
    mailingAddressCity,
    mailingAddressState,
    mailingAddressZipCode,
    phoneNumber,
    facebookLink,
    twitterLink,
    instagramLink,
    contactEmails,
    youTubeLink,
  };

  const cookieBanner = {
    cookieBannerCopy,
    cookieBannerCtaCopy,
  };

  return (
    <DefaultLayout
      hideDonate
      footerData={footerData}
      cookieBanner={cookieBanner}
      pageName="Apply For A Grant"
    >
      <Meta {...globalSiteData} />
      <ApplyForGrant {...applyForGrant} />
      <SocialModule
        eyebrow={socialModuleEyebrow}
        title={socialModuleTitle}
        subtitle={socialModuleSubtitle}
        links={[
          {
            label: 'Instagram',
            url: instagramLink,
          },
          {
            label: 'Facebook',
            url: facebookLink,
          },
          {
            label: 'Twitter',
            url: twitterLink,
          },
          {
            label: 'YouTube',
            url: youTubeLink,
          },
        ]}
      />
      <DonationModule
        donationModuleEyebrow={donationModuleEyebrow}
        donationModuleTitle={donationModuleTitle}
        donationModuleSubtitle={donationModuleSubtitle}
      />
    </DefaultLayout>
  );
};

export default Grant;

// Graphql Query
export const pageQuery = graphql`
  {
    allContentfulGlobalSiteData {
      edges {
        node {
          ...Meta
          ...SocialModule
          ...DonationModule
        }
      }
    }

    allContentfulApplyForGrantPage {
      edges {
        node {
          ...ApplyForGrant
        }
      }
    }
  }
`;
