const resources = [
  {
    title: 'for grant seekers',
    resources: [
      {
        title: 'ASSISTANCE TO FIREFIGHTERS GRANT PROGRAM',
        copy:
          'Presented by the U.S. Department of Homeland Security’s Preparedness Directorate’s Office of Grants and Training in cooperation with \n' +
          'the U.S. Fire Administration. The Assistance to Firefighters Grant (AFG) is a competitive grant opportunity for local fire departments and \n' +
          'Emergency Medical Service (EMS) organizations that are not affiliated with a hospital. The AFG funds activities such as purchasing \n' +
          'firefighting equipment, personal protection equipment, training, firefighting vehicles, and firefighter/first responder safety projects.',
      },
      {
        title: 'FIREGRANTS.INFO',
        copy:
          'Presented by the U.S. Department of Homeland Security’s Preparedness Directorate’s Office of Grants and Training in cooperation with \n' +
          'the U.S. Fire Administration. The Assistance to Firefighters Grant (AFG) is a competitive grant opportunity for local fire departments and \n' +
          'Emergency Medical Service (EMS) organizations that are not affiliated with a hospital. The AFG funds activities such as purchasing \n' +
          'firefighting equipment, personal protection equipment, training, firefighting vehicles, and firefighter/first responder safety projects.',
      },
      {
        title: 'THE FOUNDATION CENTER',
        copy:
          'Presented by the U.S. Department of Homeland Security’s Preparedness Directorate’s Office of Grants and Training in cooperation with \n' +
          'the U.S. Fire Administration. The Assistance to Firefighters Grant (AFG) is a competitive grant opportunity for local fire departments and \n' +
          'Emergency Medical Service (EMS) organizations that are not affiliated with a hospital. The AFG funds activities such as purchasing \n' +
          'firefighting equipment, personal protection equipment, training, firefighting vehicles, and firefighter/first responder safety projects.',
      },
      {
        title: 'VOLUNTEER FIRE ASSISTANCE',
        copy:
          'Presented by the U.S. Department of Homeland Security’s Preparedness Directorate’s Office of Grants and Training in cooperation with \n' +
          'the U.S. Fire Administration. The Assistance to Firefighters Grant (AFG) is a competitive grant opportunity for local fire departments and \n' +
          'Emergency Medical Service (EMS) organizations that are not affiliated with a hospital. The AFG funds activities such as purchasing \n' +
          'firefighting equipment, personal protection equipment, training, firefighting vehicles, and firefighter/first responder safety projects.',
      },
    ],
  },
  {
    title: 'Mental & Physical Health',
    resources: [
      {
        title: 'Lorem ipsum dolor sit amet',
        copy:
          'Presented by the U.S. Department of Homeland Security’s Preparedness Directorate’s Office of Grants and Training in cooperation with \n' +
          'the U.S. Fire Administration. The Assistance to Firefighters Grant (AFG) is a competitive grant opportunity for local fire departments and \n' +
          'Emergency Medical Service (EMS) organizations that are not affiliated with a hospital. The AFG funds activities such as purchasing \n' +
          'firefighting equipment, personal protection equipment, training, firefighting vehicles, and firefighter/first responder safety projects.',
      },
      {
        title: 'Lorem ipsum dolor sit amet',
        copy:
          'Presented by the U.S. Department of Homeland Security’s Preparedness Directorate’s Office of Grants and Training in cooperation with \n' +
          'the U.S. Fire Administration. The Assistance to Firefighters Grant (AFG) is a competitive grant opportunity for local fire departments and \n' +
          'Emergency Medical Service (EMS) organizations that are not affiliated with a hospital. The AFG funds activities such as purchasing \n' +
          'firefighting equipment, personal protection equipment, training, firefighting vehicles, and firefighter/first responder safety projects.',
      },
    ],
  },
  {
    title: 'FIREFIGHTING COMMUNITY',
    resources: [
      {
        title: 'Lorem ipsum dolor sit amet',
        copy:
          'Presented by the U.S. Department of Homeland Security’s Preparedness Directorate’s Office of Grants and Training in cooperation with \n' +
          'the U.S. Fire Administration. The Assistance to Firefighters Grant (AFG) is a competitive grant opportunity for local fire departments and \n' +
          'Emergency Medical Service (EMS) organizations that are not affiliated with a hospital. The AFG funds activities such as purchasing \n' +
          'firefighting equipment, personal protection equipment, training, firefighting vehicles, and firefighter/first responder safety projects.',
      },
      {
        title: 'Lorem ipsum dolor sit amet',
        copy:
          'Presented by the U.S. Department of Homeland Security’s Preparedness Directorate’s Office of Grants and Training in cooperation with \n' +
          'the U.S. Fire Administration. The Assistance to Firefighters Grant (AFG) is a competitive grant opportunity for local fire departments and \n' +
          'Emergency Medical Service (EMS) organizations that are not affiliated with a hospital. The AFG funds activities such as purchasing \n' +
          'firefighting equipment, personal protection equipment, training, firefighting vehicles, and firefighter/first responder safety projects.',
      },
      {
        title: 'Lorem ipsum dolor sit amet',
        copy:
          'Presented by the U.S. Department of Homeland Security’s Preparedness Directorate’s Office of Grants and Training in cooperation with \n' +
          'the U.S. Fire Administration. The Assistance to Firefighters Grant (AFG) is a competitive grant opportunity for local fire departments and \n' +
          'Emergency Medical Service (EMS) organizations that are not affiliated with a hospital. The AFG funds activities such as purchasing \n' +
          'firefighting equipment, personal protection equipment, training, firefighting vehicles, and firefighter/first responder safety projects.',
      },
      {
        title: 'Lorem ipsum dolor sit amet',
        copy:
          'Presented by the U.S. Department of Homeland Security’s Preparedness Directorate’s Office of Grants and Training in cooperation with \n' +
          'the U.S. Fire Administration. The Assistance to Firefighters Grant (AFG) is a competitive grant opportunity for local fire departments and \n' +
          'Emergency Medical Service (EMS) organizations that are not affiliated with a hospital. The AFG funds activities such as purchasing \n' +
          'firefighting equipment, personal protection equipment, training, firefighting vehicles, and firefighter/first responder safety projects.',
      },
      {
        title: 'Lorem ipsum dolor sit amet',
        copy:
          'Presented by the U.S. Department of Homeland Security’s Preparedness Directorate’s Office of Grants and Training in cooperation with \n' +
          'the U.S. Fire Administration. The Assistance to Firefighters Grant (AFG) is a competitive grant opportunity for local fire departments and \n' +
          'Emergency Medical Service (EMS) organizations that are not affiliated with a hospital. The AFG funds activities such as purchasing \n' +
          'firefighting equipment, personal protection equipment, training, firefighting vehicles, and firefighter/first responder safety projects.',
      },
      {
        title: 'Lorem ipsum dolor sit amet',
        copy:
          'Presented by the U.S. Department of Homeland Security’s Preparedness Directorate’s Office of Grants and Training in cooperation with \n' +
          'the U.S. Fire Administration. The Assistance to Firefighters Grant (AFG) is a competitive grant opportunity for local fire departments and \n' +
          'Emergency Medical Service (EMS) organizations that are not affiliated with a hospital. The AFG funds activities such as purchasing \n' +
          'firefighting equipment, personal protection equipment, training, firefighting vehicles, and firefighter/first responder safety projects.',
      },
      {
        title: 'Lorem ipsum dolor sit amet',
        copy:
          'Presented by the U.S. Department of Homeland Security’s Preparedness Directorate’s Office of Grants and Training in cooperation with \n' +
          'the U.S. Fire Administration. The Assistance to Firefighters Grant (AFG) is a competitive grant opportunity for local fire departments and \n' +
          'Emergency Medical Service (EMS) organizations that are not affiliated with a hospital. The AFG funds activities such as purchasing \n' +
          'firefighting equipment, personal protection equipment, training, firefighting vehicles, and firefighter/first responder safety projects.',
      },
      {
        title: 'Lorem ipsum dolor sit amet',
        copy:
          'Presented by the U.S. Department of Homeland Security’s Preparedness Directorate’s Office of Grants and Training in cooperation with \n' +
          'the U.S. Fire Administration. The Assistance to Firefighters Grant (AFG) is a competitive grant opportunity for local fire departments and \n' +
          'Emergency Medical Service (EMS) organizations that are not affiliated with a hospital. The AFG funds activities such as purchasing \n' +
          'firefighting equipment, personal protection equipment, training, firefighting vehicles, and firefighter/first responder safety projects.',
      },
    ],
  },
];
export default resources;
