import React, { useState, useEffect } from 'react';
import CommunityResources from '@components/CommunityResources/CommunityResources';
import GeneralInfo from '@components/GeneralInfo/GeneralInfo';
import SkinnyHero from '@shared/SkinnyHero/SkinnyHero';
import resources from '@data/communityResources';
import GrantTestimonial from '@components/GrantTestimonial/GrantTestimonial.query';
import { css } from '@utils';
import Styles from './ApplyForGrant.module.scss';

const ApplyForGrant = ({
  heroEyebrow,
  heroTitle,
  heroDesktopBackgroundImage,
  heroMobileBackgroundImage,
  grantInstructions,
  grantInstructionsTitle,
  grantInstructionsSubtitle,
  grantApplyCtaCopy,
  grantApplyCtaUrl,
  grantInstructionsImage,
  grantTestimonialModule,
  communityResourcesModule,
}) => {
  const [mounted, setMounted] = useState(false);

  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) {
    return <div className={Styles.applyForGrant} />;
  }

  return (
    <div
      className={css(Styles.applyForGrant, imageLoaded && Styles.imageLoaded)}
    >
      <SkinnyHero
        heroEyebrow={heroEyebrow}
        heroTitle={heroTitle}
        heroDesktopBackgroundImage={heroDesktopBackgroundImage}
        heroMobileBackgroundImage={heroMobileBackgroundImage}
        setImageLoaded={setImageLoaded}
        loaded={imageLoaded}
      />

      <GeneralInfo
        title={grantInstructionsTitle}
        subtitle={grantInstructionsSubtitle}
        copy={grantInstructions}
        cta={grantApplyCtaCopy}
        ctaUrl={grantApplyCtaUrl}
        image={grantInstructionsImage}
        loaded={imageLoaded}
      />

      <CommunityResources
        resources={resources}
        communityResourcesModule={communityResourcesModule}
      />
      <GrantTestimonial {...grantTestimonialModule} />
    </div>
  );
};

export default ApplyForGrant;
