/* eslint-disable */

import React from "react";
import Styles from "./CommunityResources.module.scss";
import {useEffect, useState} from "react";
import Resource from './Resource';
import {TweenMax} from "gsap";
import useWindowSize from '@hooks/useWindowSize';
import { InView } from 'react-intersection-observer';
import { css } from '@utils';



let mobileTabs = [];
let prevTab = -1;

const CommunityResources = props => {    
    const [tab, setTab] = useState(-1);
    const { windowWidth } = useWindowSize();

    useEffect(() => {

        if(windowWidth >= 768 && tab === -1) setTab(0);

        mobileTabs = Array.from(document.querySelectorAll(`.${Styles.tabMobileResources}`));

        if(tab === -1) {
            mobileTabs.map((el, index) => {
                el.style.height = `0px`;
            });
        }

        if(mobileTabs.length > 0) {

            //Check the height for all the elements;
            mobileTabs.map(el => el.style.height = "auto");
            const heights = mobileTabs.map(el => el.getBoundingClientRect().height);

            mobileTabs.map((el, index) => {
                TweenMax.killTweensOf(el);
                el.style.height = index === prevTab ? `${heights[index]}` : `0px`;
            });

            if(tab >= 0) {

                const currentTab = mobileTabs[tab];

                //Close the opened tab and open the new one
                if(prevTab !== -1) {

                    mobileTabs[prevTab].style.height = "0px";
                    currentTab.style.height = `${heights[tab]}px`;

                    if(prevTab < tab) {
                        window.scrollTo(0, window.pageYOffset - heights[prevTab]);
                    }

                }

                currentTab.style.height = `0px`;
                TweenMax.to(currentTab, heights[tab] * 0.0008, { css: {height: `${heights[tab]}`} } )

            } else {

                const currentTab = mobileTabs[prevTab];
                TweenMax.to(currentTab, heights[prevTab] * 0.0002, { css: {height: `0`} } );

            }

            prevTab = tab;

        } 

    });

    const updateTabMobile = index => {
        const ii = index === tab? -1 : index;
        setTab(ii);
    }

    const {eyebrow, title, subtitle, communityResourcesList} = props.communityResourcesModule;

    let orderedResources = [];
    const data = communityResourcesList.map(el => el.category);
    let categories = data.filter((item, index) => data.indexOf(item) === index);
    categories = [categories[0], categories[2], categories[1]];

    categories.map(element => {
        const resourcesList = communityResourcesList.filter(el => el.category === element);
        orderedResources.push(resourcesList);
    });

    return (
        <InView triggerOnce threshold={0.2}>
        {({ inView, ref }) => (
        <div ref={ref} className = {css(Styles.wrapper, inView && Styles.inView)}>

            <div className = {Styles.header}>
                <div className = {Styles.eyebrow}>{eyebrow}</div>
                <div className = {Styles.title}>{title}</div>
                <div className = {Styles.subtitle}>{subtitle}</div>
            </div>

            <div className = {Styles.desktop}>
                <div className = {Styles.tabs}>
                    {
                        categories.map((_tab, index) => {
                            return(<div key = {`tab${index}`} className = {`${Styles.tab} ${index === tab ? Styles.tabActive : ""}`}  onClick = {() => {setTab(index)}}>{_tab}<span className = {`${Styles.tabBorder} ${index === tab ? Styles.tabBorderActive : ""}`} /></div>)
                        })
                    }
                    </div>
                {tab >= 0 && (<div className = {Styles.resources} key={tab}>
                    {
                        orderedResources[tab].map((resource, index) => {
                            return (<Resource key = {`resourceDesktop${index}`} data = {resource} category={categories[tab]} />)
                        })
                    }
                </div>)}
            </div>

            <div className = {Styles.mobile}>
                {
                    categories.map((_tab, ii) => {

                        return(
                           <div key = {`tabMobile${ii}`} className = {`${Styles.tabMobile} ${tab === ii ? Styles.tabMobileActive : ""}`}>
                                <div onClick = {() => updateTabMobile(ii)} className = {Styles.tabMobileTitle}>
                                    {_tab} <span className = {Styles.resourcesAmount}>{orderedResources[ii].length}</span>
                                    <svg className = {`${Styles.tabMobileArrow} ${tab === ii ? Styles.tabMobileArrowActive : ""}`} width="12.118" height="7.059" viewBox="0 0 12.118 7.059">
                                        <path d="M0,0,2.613,2.613,4.645,4.645,6.58,2.71,9.29,0" transform="translate(1.414 1.414)" fill="none" stroke="#ffa200" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                                    </svg>
                                </div>
                                <div className = {`${Styles.tabMobileResources}`}>
                                    {
                                        orderedResources[ii].map((resource, index) => {
                                            return (<Resource key = {`resourceMobile${index}`} data = {resource} />)
                                        })
                                    }
                                </div>
                           </div>
                        )
                    })
                }
            </div>

        </div>
        )}
        </InView>
    )

}

export default CommunityResources;