/* eslint-disable */
import React from 'react';
import Styles from './Resource.module.scss';
import { InView } from 'react-intersection-observer';
import {ShortArrow} from '@shared/Icons/Icons';
import { css, trackEvent } from '@utils';


const Resource = props => {
  
  const onResourceClick = (e) => {
    e.preventDefault();
    const {data,  category} = props;  
    const {title} = data;    
    
    trackEvent({
      category: 'Apply For A Grant',
      action: data && data.url ? data.url : '',
      label: `${category || ''}_${title || ''}`,
    });

    if(data && data.url) {
      const newWindow = window.open(props.data.url, '_blank');
      newWindow.opener = null;
    }
  }

    return (
        <InView triggerOnce threshold={0.3}>
            {({ inView, ref }) => (
            <div ref={ref}  className = {css(Styles.wrapper, inView && Styles.inView)}>
                <div className = {Styles.info}>
                    <div className = {Styles.title}>{props.data.title}</div>
                    <div className = {Styles.copy} dangerouslySetInnerHTML={{__html: `${props.data.description}`}}/>
                </div>
                <a onClick={onResourceClick}>
                  <div className = {Styles.arrow}>
                      <ShortArrow iconStyles={Styles.arrowIcon} />
                  </div>
                </a>
            </div>
            )}
        </InView>
    )
}

export default Resource;