import React from 'react';
import Img from 'gatsby-image/withIEPolyfill';
import { RightArrow, ShortArrow } from '@shared/Icons/Icons';
import { css, trackEvent } from '@utils';
import Styles from './GeneralInfo.module.scss';

const GeneralInfo = ({ title, subtitle, image, copy, cta, ctaUrl, loaded }) => {
  const onApplyForGrantClick = e => {
    e.preventDefault();
    trackEvent({
      category: 'Apply For A Grant',
      action: ctaUrl,
      label: 'Apply For Grant',
    });
    const newWindow = window.open(ctaUrl, '_blank');
    newWindow.opener = null;
  };

  return (
    <div className={css(Styles.wrapper, loaded && Styles.loaded)}>
      <div className={Styles.generalInfo}>
        <div className={Styles.title}>{title}</div>
        <div className={Styles.subtitle}>{subtitle}</div>
        <div className={Styles.imageMobile}>
          {image && image.fluid && <Img fluid={image.fluid} />}
        </div>
        <div className={Styles.grantImage}>
          {image && image.fluid && <Img fluid={image.fluid} />}
          <a
            className={Styles.grantsButtonDesktop}
            href={ctaUrl}
            onClick={onApplyForGrantClick}
          >
            <div className={Styles.grantsButtonDesktop_copy}>{cta}</div>
            <ShortArrow iconStyles={Styles.grantsButtonDesktop_arrow} />
          </a>
        </div>
        <div
          className={Styles.copy}
          dangerouslySetInnerHTML={{ __html: copy }}
        />

        <div className={Styles.applyCtaContainer}>
          <a
            className={Styles.applyCta}
            href={ctaUrl}
            onClick={onApplyForGrantClick}
          >
            {cta}
          </a>

          <div className={Styles.arrowContainer}>
            <RightArrow iconStyles={Styles.applyCtaArrow} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralInfo;
