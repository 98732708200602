import React from 'react';
import Img from 'gatsby-image/withIEPolyfill';
import useWindowSize from '@hooks/useWindowSize';
import { css } from '@utils';
import Styles from './SkinnyHero.module.scss';

const SkinnyHero = ({
  heroMobileBackgroundImage,
  heroDesktopBackgroundImage,
  heroEyebrow,
  heroTitle,
  setImageLoaded,
  loaded,
}) => {
  const { windowWidth } = useWindowSize();

  return (
    <div className={css(Styles.hero, loaded && Styles.loaded)}>
      {windowWidth <= 768 ? (
        <div className={Styles.mobileImage}>
          {heroMobileBackgroundImage && heroMobileBackgroundImage.fluid && (
            <Img
              fluid={heroMobileBackgroundImage.fluid}
              onLoad={() => {
                setImageLoaded(true);
              }}
              objectFit="cover"
              style={{ width: '100%', height: '100%' }}
            />
          )}
        </div>
      ) : (
        <div className={Styles.desktopImage}>
          {heroDesktopBackgroundImage && heroDesktopBackgroundImage.fluid && (
            <Img
              fluid={heroDesktopBackgroundImage.fluid}
              onLoad={() => {
                setImageLoaded(true);
              }}
              objectFit="cover"
              style={{ width: '100%', height: '100%' }}
            />
          )}
        </div>
      )}

      <div className={Styles.backgroundImageMask} />
      <div className={Styles.copyTintBackground} />
      <div className={Styles.copyContainer}>
        <div className={Styles.heroEyebrow}>{heroEyebrow}</div>
        <h2 className={Styles.heroTitle}>{heroTitle}</h2>
      </div>
    </div>
  );
};

export default SkinnyHero;
